import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { sortByDefault } from '@app/reducers/helpers/default-sorting.helper';
import { Dictionary } from '@ngrx/entity';
import { compose, Store } from '@ngrx/store';
import { CustomFieldsService } from '@reducers/orm/custom-fields/custom-fields.service';
import groupBy from 'lodash-es/groupBy';
import mapValues from 'lodash-es/mapValues';
import omit from 'lodash-es/omit';
import { createSelector } from 'reselect';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import u from 'updeep';

import { hexToRGB, isColorDark } from '../../../shared/contrast.helper';
import { AppState } from '../../index';
import { filterDeleted, mapAndSortEntities } from '../../shared/entity.helper';
import { ShiftAction } from './shift.action';
import { ShiftApi } from './shift.api';
import { ShiftModel, ShiftState } from './shift.model';

const gray = '#98a0ab';

export const FallbackShift: ShiftModel = {
  id: '',
  account_id: '',
  department_id: '',
  rate_card_id: '',
  name: '',
  long_name: '',
  description: '',
  starttime: '00:00:00',
  endtime: '00:00:00',
  hide_end_time: false,
  break: 0,
  meals: 0,
  color: gray,
  color_is_dark: true,
  color_rgb: hexToRGB(gray),
  order: '1000',
  is_task: false,
  deleted: false,
  created: null,
};

@Injectable()
export class ShiftService {
  public state: Observable<ShiftState>;

  public constructor(
    private store: Store<AppState>,
    private api: ShiftApi,
    private customFieldsService: CustomFieldsService,
  ) {
    this.state = store.select((state) => state.orm.shifts);
  }

  public getShiftsForDepartment(departmentId: string) {
    return this.store
      .select(getActiveShiftsGroupedByDepartment)
      .pipe(map((shiftsGroupedByDepartment) => shiftsGroupedByDepartment[departmentId] ?? []));
  }

  public add(shiftData) {
    return this.api.add(shiftData, ShiftAction.add(shiftData)).pipe(
      map((response) => {
        this.store.dispatch(ShiftAction.addSuccess(response));
        return response;
      }),
      catchError((err) => {
        this.store.dispatch(ShiftAction.addFailed(err));
        return observableThrowError(err);
      }),
    );
  }

  public update(shiftId, shiftData) {
    return this.api.update(shiftId, shiftData, ShiftAction.update(shiftData)).pipe(
      map((response) => {
        this.store.dispatch(ShiftAction.updateSuccess(response));
        return observableOf(response);
      }),
      catchError((err) => {
        this.store.dispatch(ShiftAction.updateFailed(err));
        return observableThrowError(err);
      }),
    );
  }

  public batch(shifts, options?) {
    const data = { Shift: shifts };

    return this.api.batch(data, options).pipe(
      tap((response) => {
        this.store.dispatch(ShiftAction.updateSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(ShiftAction.updateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public remove(shiftId) {
    return this.api.remove(shiftId, ShiftAction.remove(shiftId)).pipe(
      map((response) => {
        this.store.dispatch(ShiftAction.removeSuccess(response));
        return observableOf(response);
      }),
      catchError((err) => {
        this.store.dispatch(ShiftAction.removeFailed(shiftId, err));
        return observableThrowError(err);
      }),
    );
  }

  public save(shiftData) {
    const shift = omit(shiftData, ['Skill', 'customField']);

    if (shift.surcharge_id === 'null') {
      shift.surcharge_id = null;
    }

    if (shift.is_task) {
      shift.rate_card_id = null;
    }

    if (shift.rate_card_id === 'null') {
      shift.rate_card_id = null;
    }

    if (!shift.starttime) {
      shift.starttime = '00:00';
    }

    if (!shift.endtime) {
      shift.endtime = '00:00';
    }

    const formValue = {
      id: shiftData.id,
      Shift: shift,
      Skill: shiftData.Skill,
    };

    if (shiftData.customField) {
      formValue.Shift.custom_fields = this.customFieldsService.mapFormDataToRequestData(
        // @FIXME - Refactor mapFormDataToRequestData to accept things that aren't form controls.
        new UntypedFormControl(shiftData.customField, []),
      );
    }
    if (shiftData.id) {
      return this.update(shiftData.id, formValue);
    }

    shiftData = u.omit('id', formValue);

    return this.add(shiftData);
  }
}

export const sortShifts = (shifts: ShiftModel[]) => sortByDefault<ShiftModel>(shifts);
export const mapAndSortShifts = mapAndSortEntities(sortShifts);

export const getShiftState = (state: AppState) => state.orm.shifts;

export const getShiftIds = compose((state) => state.items, getShiftState);

export const setShiftColor = (shift: ShiftModel, color?: string) => {
  const shiftColor = color || shift.color;

  const color_rgb = hexToRGB(shiftColor);
  const color_is_dark = isColorDark(shiftColor);

  return {
    ...shift,
    color: shiftColor,
    color_is_dark,
    color_rgb,
  };
};

export const getShiftEntities = createSelector(getShiftState, (state) =>
  mapValues(state.itemsById, (shift) => setShiftColor(shift)),
);
export const getShifts = createSelector(getShiftIds, getShiftEntities, mapAndSortShifts);

export const getActiveShifts = createSelector(getShifts, (shifts: ShiftModel[]) => filterDeleted(shifts));

export const getActiveShiftIds = createSelector(getActiveShifts, (shifts: ShiftModel[]) =>
  shifts.map((shift) => shift.id),
);

export const groupShiftsByDepartment = <T>(shifts): Dictionary<T[]> => groupBy(shifts, 'department_id');

export const getActiveShiftsGroupedByDepartment = createSelector(getShifts, (shifts) => {
  shifts = shifts.filter((shift) => shift.deleted === false);

  const groupedShifts = groupShiftsByDepartment<ShiftModel>(shifts);
  return groupedShifts ? groupedShifts : {};
});

export const getShift = (shiftId) => compose((shiftsById) => shiftsById[shiftId], getShiftEntities);

export const filterShiftOptionsPerDepartment =
  (departmentId, mustHaveId, includeTask = false) =>
  (shifts: ShiftModel[]) =>
    shifts.filter((shift) => {
      if (shift.department_id !== departmentId) return false;

      if (shift.id === mustHaveId) return true;

      if (shift.is_task && !includeTask) return false;

      return !shift.deleted;
    });
