import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { arrayOf, normalize } from 'normalizr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiGateway } from '../../../api/ApiGateway.service';
import { assignSchemaEntity } from '../../shared/assign';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { ShiftSchema } from '../../shared/schemas';

@Injectable()
export class ShiftApi {
  private endpoint = 'shifts/';

  public constructor(private gateway: ApiGateway) {}

  public add(shiftData, dispatchStart?: Action): Observable<any> {
    return this.gateway.post(this.endpoint, this.normalizeShiftPayload(shiftData), undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Shift', res)),
      map((data) => normalize(data, ShiftSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public update(shiftId, shiftData, dispatchStart?: Action): Observable<any> {
    return this.gateway.put(this.endpoint + shiftId, shiftData, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Shift', res)),
      map((data) => normalize(data, ShiftSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public batch(shifts, options?): Observable<any> {
    shifts.Shift?.forEach(this.normalizeShiftPayload);

    return this.gateway.post(this.endpoint + 'batch', shifts, options).pipe(
      map((res) => reformatListResponse('Shift', res)),
      map((res) => normalize(res, arrayOf(ShiftSchema), { assignEntity: assignSchemaEntity })),
    );
  }

  public remove(shiftId, dispatchStart?: Action): Observable<any> {
    return this.gateway.delete(this.endpoint + shiftId, undefined, dispatchStart);
  }

  private normalizeShiftPayload(shift) {
    // break is mandatory in POST requests for NEW shifts (templates)
    // in batch requests, if it doesn't have an ID, then it's a new shift
    if (!shift.break && (!shift.id || shift.break !== undefined)) {
      shift.break = 0;
    }

    return shift;
  }
}
